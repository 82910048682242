import React, { createContext, HTMLAttributes, useCallback, useRef } from 'react';
import { createPortal as createReactPortal } from 'react-dom';

const PORTAL_CONTAINER_KEY = '__florencecard-portal-container__';

type CreatePortalFunction = (
  children: Parameters<typeof createReactPortal>[0],
) => ReturnType<typeof createReactPortal> | null;

export const PortalContext = createContext<CreatePortalFunction>(() => {
  return null;
});

PortalContext.displayName = 'PortalContext';

export function PortalProvider({
  children,
  ...divAttrs
}: Omit<HTMLAttributes<HTMLDivElement>, 'id'>) {
  const portalContainer = useRef<HTMLDivElement>(null);
  const createPortal = useCallback<CreatePortalFunction>((node) => {
    if (portalContainer.current == null) {
      return null;
    }

    return createReactPortal(node, portalContainer.current);
  }, []);

  return (
    <PortalContext.Provider value={createPortal}>
      {children}
      <div id={PORTAL_CONTAINER_KEY} ref={portalContainer} {...divAttrs} />
    </PortalContext.Provider>
  );
}
