import type { WithCustomElement } from '@florencecard-components/as';
import { Box, ExtendBoxCSSProperties } from '@florencecard-components/box';
import type { ResponsiveValue } from '@florencecard-components/responsive';
import { useResponsive } from '@florencecard-components/responsive';
import React, { ElementType, memo } from 'react';

export type SpacingDirection = 'vertical' | 'horizontal';

export type SpacingProps<Element extends ElementType = 'button'> = WithCustomElement<
  Element,
  ExtendBoxCSSProperties<{
    children?: never;
    direction?: SpacingDirection;
    size: ResponsiveValue<string | number | undefined>;
  }>
>;

export const Spacing = memo(function Spacing({
  as = 'div',
  flex = 'none',
  direction = 'vertical',
  size,
  ...props
}: SpacingProps) {
  const mq = useResponsive();

  return (
    <Box
      as={as}
      flex={flex}
      _css={[
        mq({
          width: direction === 'horizontal' ? size : undefined,
          height: direction === 'vertical' ? size : undefined,
        }),
      ]}
      {...props}
    />
  );
});
