import { css, keyframes } from '@emotion/react';
import { usePortal } from '@florencecard-components/portal';
import { usePreventScroll } from '@florencecard-components/prevent-scroll';
import { coerceCssPixelValue } from '@florencecard-lib/coercion';
import { colors } from '@florencecard-shared/colors';
import classNames from 'classnames';
import React, { createContext, forwardRef, HTMLProps, useContext, useMemo, useRef } from 'react';
import type { ExtendHTMLProps } from './types';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const appearAnimation = keyframes`
  from {
    transform: translate(-50%, -55%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const styles = css`
  &[aria-hidden='true'] {
    display: none;
  }

  .Modal__overlay {
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &:focus {
      outline: 0;
    }
  }

  .Modal__content {
    background-color: ${colors.white};
    z-index: 30;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    border-radius: 6px;
    border: none;
  }

  &:not([aria-hidden='true']) > .Modal__overlay {
    animation: ${fadeInAnimation} 200ms 1 both;
  }

  &:not([aria-hidden='true']) > .Modal__content {
    animation: ${appearAnimation} 335ms 150ms 1 both;
  }

  .Modal__head {
    padding: 12px 12px 8px 12px;
    display: flex;
    align-items: center;

    > .Modal__title {
      flex: 1 1 auto;
    }

    > .Modal__closeButton {
      flex: none;
    }
  }

  .Modal__title {
    margin: 0;
    font-size: 1rem;
    line-height: 1.25;
    font-weight: 700;
    color: ${colors.grayBold};
  }

  .Modal__closeButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;

    > svg {
      width: 24px;
      height: 24px;
    }

    &:focus {
      outline: 0;
    }
  }
`;

let uniqueId = 0;

interface ModalComponentContextValue {
  id: string;
  titleId: string;
  descriptionId: string;
  close?(): void;
}

const ModalComponentContext = createContext<ModalComponentContextValue | undefined>(undefined);
ModalComponentContext.displayName = 'ModalComponentContext';

export type ModalProps = ExtendHTMLProps<
  HTMLDivElement,
  {
    open?: boolean;
    onClose?(): void;
    width?: string | number;
    minWidth?: string | number;
    maxWidth?: string | number;
    height?: string | number;
    minHeight?: string | number;
    maxHeight?: string | number;
    closeWhenOverlayClick?: boolean;
  }
>;

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const {
    open = false,
    onClose,
    role = 'Modal',
    closeWhenOverlayClick = true,
    children,
    'aria-labelledby': ariaLabelledby,
    'aria-describedby': ariaDescribedby,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    className,
    style,
    ...otherProps
  } = props;

  const createPortal = usePortal();
  const containerRef = useRef<HTMLDivElement>(null);
  const idRef = useRef(`florencecard-components-modal-${uniqueId++}`);

  const contextValue = useMemo((): ModalComponentContextValue => {
    const id = idRef.current;

    return {
      id,
      titleId: `${id}-title`,
      descriptionId: `${id}-description`,
      close: onClose,
    };
  }, [onClose]);

  usePreventScroll(open);

  return (
    <ModalComponentContext.Provider value={contextValue}>
      {createPortal(
        <div
          ref={containerRef}
          aria-hidden={!open}
          className={classNames('Modal', {
            'Modal--open': open,
          })}
          css={styles}
        >
          <div
            className="Modal__overlay"
            tabIndex={-1}
            onClick={closeWhenOverlayClick ? onClose : undefined}
          />
          <div
            ref={ref}
            role={role}
            aria-labelledby={ariaLabelledby ?? contextValue.titleId}
            aria-describedby={ariaDescribedby ?? contextValue.descriptionId}
            className={classNames('Modal__content', className)}
            style={{
              ...style,
              width: coerceCssPixelValue(width),
              minWidth: coerceCssPixelValue(minWidth),
              maxWidth: coerceCssPixelValue(maxWidth),
              height: coerceCssPixelValue(height),
              minHeight: coerceCssPixelValue(minHeight),
              maxHeight: coerceCssPixelValue(maxHeight),
            }}
            {...otherProps}
          >
            {children}
          </div>
        </div>,
      )}
    </ModalComponentContext.Provider>
  );
});

Modal.displayName = 'Modal';

export type ModalHeadProps = HTMLProps<HTMLDivElement>;

export function ModalHead({ children, className, ...props }: ModalHeadProps) {
  return (
    <div className={classNames('Modal__head', className)} {...props}>
      {children}
    </div>
  );
}

export type ModalTitleProps = HTMLProps<HTMLParagraphElement>;

export function ModalTitle({ id, children, className, ...props }: ModalTitleProps) {
  const context = useContext(ModalComponentContext);

  return (
    <p
      id={id ?? context?.titleId}
      role="heading"
      aria-level={1}
      className={classNames('Modal__title', className)}
      {...props}
    >
      {children}
    </p>
  );
}

export type ModalCloseButtonProps = Omit<HTMLProps<HTMLButtonElement>, 'children' | 'type'> & {
  children?: never;
};

const closeButtonSVG =
  '<svg width="41" height="40" viewBox="0 0 41 40" xmlns="http://www.w3.org/2000/svg">\n' +
  '    <g fill="currentColor" fill-rule="evenodd" transform="rotate(-45 20 20)">\n' +
  '        <path d="M21 0h-1v40h1z"/>\n' +
  '        <path d="M.5 19.5v1h40v-1z"/>\n' +
  '    </g>\n' +
  '</svg>';

export function ModalCloseButton({
  'aria-label': ariaLabel,
  onClick,
  className,
  ...props
}: ModalCloseButtonProps) {
  const context = useContext(ModalComponentContext);

  return (
    <button
      type="button"
      dangerouslySetInnerHTML={{
        __html: closeButtonSVG,
      }}
      aria-label={ariaLabel ?? '닫기'}
      onClick={(event) => {
        context?.close?.();
        onClick?.(event);
      }}
      className={classNames('Modal__closeButton', className)}
      {...props}
    />
  );
}
