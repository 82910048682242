import { useEffect } from 'react';

export function usePreventScroll(enabled: boolean): void {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const overflow = document.body.style.overflow;
    const paddingRight = document.body.style.paddingRight;

    document.body.style.paddingRight =
      window.innerWidth - document.documentElement.clientWidth + 'px';
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = overflow;
      document.body.style.paddingRight = paddingRight;
    };
  }, [enabled]);
}
