import { createContext } from 'react';
import type { CommonModalProps, RenderModal } from './interfaces';

export interface ModalContextValue {
  open: <T, P extends CommonModalProps<T> = CommonModalProps<T>>(
    render: RenderModal<T, P>,
  ) => Promise<T | undefined>;
  close: () => void;
}

export const ModalContext = createContext<ModalContextValue>({
  open: () => {
    throw new Error('ModalContext가 초기화되지 않았습니다.');
  },
  close: () => {
    throw new Error('ModalContext가 초기화되지 않았습니다.');
  },
});

ModalContext.displayName = 'ModalContext';
