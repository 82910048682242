/* eslint-disable @typescript-eslint/no-explicit-any */
import type { As } from '@florencecard-components/as';
import type { BoxProps } from '@florencecard-components/box';
import { Box } from '@florencecard-components/box';
import React from 'react';

export type FlexProps<Element extends As = 'div'> = BoxProps<Element>;

export function Flex<Element extends As = 'div'>({
  as = 'div',
  display = 'flex',
  alignItems = 'flex-start',
  flexDirection = 'row',
  justifyContent = 'flex-start',
  ...props
}: FlexProps<Element>) {
  return (
    <Box
      as={as}
      display={display}
      flexDirection={flexDirection}
      alignItems={alignItems}
      justifyContent={justifyContent}
      {...props}
    />
  );
}

Flex.Center = function FlexCenter<Element extends As>(props: FlexProps<Element>) {
  return <Flex alignItems="center" justifyContent="center" {...(props as any)} />;
};

Flex.CenterVertical = function FlexCenterVertical<Element extends As>(props: FlexProps<Element>) {
  return <Flex alignItems="center" {...(props as any)} />;
};

Flex.CenterHorizontal = function FlexCenterHorizontal<Element extends As>(
  props: FlexProps<Element>,
) {
  return <Flex alignItems="center" {...(props as any)} />;
};
